import { trpc } from "@rallly/backend";
import { Badge } from "@rallly/ui/badge";
import Link from "next/link";

import { Trans } from "@/components/trans";
import { CurrentUserAvatar } from "@/components/user";

import { IfAuthenticated, IfGuest, useUser } from "./user-provider";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Plan = () => {
  const { isFetched, data } = trpc.user.getBilling.useQuery();
  if (!isFetched) {
    return null;
  }

  const isPlus = data && data.endDate.getTime() > Date.now();

  if (isPlus) {
    return (
      <Badge>
        <Trans i18nKey="planPro" defaults="Pro" />
      </Badge>
    );
  }
  return (
    <Badge variant="secondary">
      <Trans i18nKey="planFree" defaults="Free" />
    </Badge>
  );
};
export const UserDropdown = (props: { type?: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useUser();
  return (
    <>
      {props.type == "content" ? (
        <div>
          {/* <div>
            <div className="grow">
              <div>{user.isGuest ? <Trans i18nKey="guest" /> : user.name}</div>
              <div className="text-muted-foreground text-xs font-normal">
                {!user.isGuest ? user.email : user.id.substring(0, 10)}
              </div>
            </div>
            <div className="ml-4">
              <Plan />
            </div>
          </div> */}
          <IfAuthenticated>
            <div>
              <Link
                href="/settings/profile"
                className="m-t-10 flex items-center gap-x-2 text-white"
              >
                {/* <UserIcon className="h-4 w-4" /> */}
                <Trans i18nKey="profile" defaults="Profile" />
              </Link>
            </div>
          </IfAuthenticated>
          <div className="mt-6">
            <Link
              href="/settings/preferences"
              className="flex items-center gap-x-2 text-white"
            >
              {/* <Settings2Icon className="h-4 w-4" /> */}
              <Trans i18nKey="preferences" defaults="Preferences" />
            </Link>
          </div>{" "}
          <div className="mt-6">
            <Link
              href="/polls"
              className="flex items-center gap-x-2 text-white sm:hidden"
            >
              {/* <ListIcon className="h-4 w-4" /> */}
              <Trans i18nKey="polls" defaults="Polls" />
            </Link>
          </div>
          <IfGuest>
            <div className="mt-6">
              <Link
                href="/login"
                className="flex items-center gap-x-2 text-white"
              >
                {/* <LogInIcon className="h-4 w-4" /> */}
                <Trans i18nKey="login" defaults="login" />
              </Link>
            </div>
            <div className="mt-6">
              <Link
                href="/register"
                className="flex items-center gap-x-2 text-white"
              >
                {/* <UserPlusIcon className="h-4 w-4" /> */}
                <Trans i18nKey="createAnAccount" defaults="Register" />
              </Link>
            </div>
            <div className="mt-6">
              <Link
                href="/logout"
                className="flex items-center gap-x-2 text-white"
              >
                {/* <RefreshCcwIcon className="h-4 w-4" /> */}
                <Trans i18nKey="forgetMe" />
              </Link>
            </div>
          </IfGuest>
          <IfAuthenticated>
            <div className="mt-6">
              <Link
                href="/logout"
                className="flex items-center gap-x-2 text-white"
              >
                {/* <LogOutIcon className="h-4 w-4" /> */}
                <Trans i18nKey="logout" />
              </Link>
            </div>
          </IfAuthenticated>
        </div>
      ) : (
        <CurrentUserAvatar size="sm" className="-ml-1" />
      )}
    </>
  );
};
