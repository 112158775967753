import { UserIcon } from "@rallly/icons";
import clsx from "clsx";
import { useRouter } from "next/router";

import { useUser } from "@/components/user-provider";
import { getRandomAvatarColor } from "@/utils/color-hash";

export const CurrentUserAvatar = ({
  size = "md",
  className,
}: Omit<UserAvatarProps, "name">) => {
  const { user } = useUser();

  return (
    <UserAvatar
      className={className}
      name={user.isGuest ? undefined : user.name}
      size={size}
    />
  );
};

interface UserAvatarProps {
  name?: string;
  size?: "sm" | "md" | "lg";
  className?: string;
}

export const UserAvatar = ({
  size = "md",
  name,
  className,
}: UserAvatarProps) => {
  const colors = name ? getRandomAvatarColor(name) : null;
  const router = useRouter();
  return (
    <span
      className={clsx(
        "inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full font-semibold",
        !name
          ? "bg-gray-200"
          : colors?.requiresDarkText
          ? "text-gray-800"
          : "text-white",
        className,
      )}
      style={{
        backgroundColor: colors?.color,
      }}
      onClick={() => {
        name ? router.push("/profile") : router.push("/login");
      }}
    >
      {name ? (
        name[0].toUpperCase()
      ) : (
        <UserIcon
          className={clsx({
            "h-4 w-4": size === "sm",
            "h-6 w-6": size === "md",
            "h-8 w-8": size === "lg",
          })}
        />
      )}
    </span>
  );
};
