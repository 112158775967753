import { cn } from "@rallly/ui";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { Toaster } from "react-hot-toast";

import { Container } from "@/components/container";
import FeedbackButton from "@/components/feedback";
import { Spinner } from "@/components/spinner";
import { Trans } from "@/components/trans";
import { UserDropdown } from "@/components/user-dropdown";

import { NextPageWithLayout } from "../../types";
import ModalProvider from "../modal/modal-provider";
import { IfAuthenticated, IfGuest, UserProvider } from "../user-provider";

const Logo = () => {
  const router = useRouter();
  const [isBusy, setIsBusy] = React.useState(false);
  React.useEffect(() => {
    const setBusy = () => setIsBusy(true);
    const setNotBusy = () => setIsBusy(false);
    router.events.on("routeChangeStart", setBusy);
    router.events.on("routeChangeComplete", setNotBusy);
    return () => {
      router.events.off("routeChangeStart", setBusy);
      router.events.off("routeChangeComplete", setNotBusy);
    };
  }, [router.events]);
  return (
    <div className="relative flex items-center justify-center gap-4 pr-8">
      <Link
        href="/polls"
        className={clsx(
          "inline-block transition-transform active:translate-y-1",
        )}
      >
        <Image
          priority={true}
          src="/static/logo.svg"
          width={80}
          height={15}
          alt="6ix Polls"
        />
      </Link>
      <div
        className={cn(
          "pointer-events-none absolute -right-0 flex items-center justify-center text-gray-500 transition-opacity delay-500",
          isBusy ? "opacity-100" : "opacity-0",
        )}
      >
        {isBusy ? <Spinner /> : null}
      </div>
    </div>
  );
};


const MainNav = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showSidebar, setShowSidebar] = useState<boolean>(false); 
  return (
    <div className="background border-b" style={{ background: "#1f1f1f" }}>
      <Container className="flex h-14 items-center justify-between gap-4">
        <div className="flex shrink-0">
          <div>

          <button
              className="group relative"
              type="button"
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <div className="relative mt-5 mr-5 flex h-[30px] w-[30px] transform items-center justify-center overflow-hidden rounded shadow-md ring-0 ring-yellow-300 transition-all duration-200 hover:ring-2">
                <div className=" flex h-[20px] w-[20px] origin-center transform flex-col justify-between overflow-hidden transition-all duration-300 duration-300 ">
                  {showSidebar ? (
                    <div className="absolute top-2.5 flex w-0 w-12 -translate-x-10 translate-x-0 transform items-center justify-between transition-all duration-500">
                      <div className="absolute h-[2px] w-5 rotate-0 rotate-45 transform bg-white transition-all delay-300 duration-500"></div>
                      <div className="absolute h-[2px] w-5 -rotate-0 -rotate-45 transform bg-white transition-all delay-300 duration-500"></div>
                    </div>
                  ) : (
                    <>
                      <div className=" h-[2px] w-7 origin-left transform bg-white transition-all delay-100 "></div>
                      <div className="h-[2px] w-7 transform rounded bg-white transition-all delay-75 duration-300 "></div>
                      <div className="h-[2px] w-7 origin-left transform bg-white transition-all duration-300 "></div>
                    </>
                  )}
                </div>
              </div>
            </button>
            
          </div>

          <Logo />
          <nav className="hidden gap-x-2 sm:flex"></nav>
        </div>
        <div className="flex items-center gap-x-4">
          {/* <nav className="hidden gap-x-2 sm:flex">
            <IfGuest>
              <NavMenuItem
                icon={LogInIcon}
                href="/login"
                label={<Trans i18nKey="login" defaults="Login" />}
              />
            </IfGuest>
            <Clock />
          </nav> */}
          <UserDropdown type="head" />
        </div>
      </Container>
{showSidebar ? (
        <div className="absolute z-10 h-screen w-screen">
          <div className="grid h-screen grid-cols-6">
            <div className="col-span-3 bg-black sm:col-span-2 md:col-span-1">
<div className="col-span-3 bg-black sm:col-span-2 md:col-span-1">
              <div className="ml-6 mr-6 mt-6">
                <Link
                  href="/new"
                  className="flex h-[40px] w-full items-center justify-center gap-2 text-white ring-0 ring-1 ring-amber-300 hover:bg-zinc-700/50"
                  onClick={() => setShowSidebar(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  <span>New Poll</span>
                </Link>
              </div>
              <div className="mt-6 ml-4">
                <Link href="/" className="text-white hover:text-amber-300">
                  <span>Home</span>
                </Link>
                <IfAuthenticated>
                  <div className="mt-6">
                    <Link
                      href="/settings/profile"
                      className="flex items-center gap-x-2 text-white hover:text-amber-300"
                      onClick={() => setShowSidebar(false)}
                    >
                      <Trans i18nKey="profile" defaults="Profile" />
                    </Link>
                  </div>
                </IfAuthenticated>
                <div className="mt-6">
                  <Link
                    href="/settings/preferences"
                    className="flex items-center gap-x-2 text-white hover:text-amber-300"
                    onClick={() => setShowSidebar(false)}
                  >
                    <Trans i18nKey="preferences" defaults="Preferences" />
                  </Link>
                </div>{" "}
                <div className="mt-6">
                  <Link
                    href="/polls"
                    className="flex items-center gap-x-2 text-white hover:text-amber-300 sm:hidden"
                    onClick={() => setShowSidebar(false)}
                  >
                    <Trans i18nKey="polls" defaults="Polls" />
                  </Link>
                </div>
                <IfGuest>
                  <div className="mt-6">
                    <Link
                      href="/login"
                      className="flex items-center gap-x-2 text-white hover:text-amber-300"
                      onClick={() => setShowSidebar(false)}
                    >
                      <Trans i18nKey="login" defaults="login" />
                    </Link>
                  </div>
                  <div className="mt-6">
                    <Link
                      href="/register"
                      className="flex items-center gap-x-2 text-white hover:text-amber-300"
                      onClick={() => setShowSidebar(false)}
                    >
                      <Trans i18nKey="createAnAccount" defaults="Register" />
                    </Link>
                  </div>
                  <div className="mt-6">
                    <Link
                      href="/logout"
                      className="flex items-center gap-x-2 text-white hover:text-amber-300"
                      onClick={() => setShowSidebar(false)}
                    >
                      <Trans i18nKey="forgetMe" />
                    </Link>
                  </div>
                </IfGuest>
                <IfAuthenticated>
                  <div className="mt-6">
                    <Link
                      href="/logout"
                      className="flex items-center gap-x-2 text-white hover:text-amber-300"
                      onClick={() => setShowSidebar(false)}
                    >
                      <Trans i18nKey="logout" />
                    </Link>
                  </div>
                </IfAuthenticated>
              </div>
            </div>
              
            </div>
            <div
              className="col-span-3 h-screen bg-gray-500/50 sm:col-span-4 md:col-span-5"
              onClick={() => setShowSidebar(false)}
            ></div>
          </div>
        </div>
      ) : (
        ""
      )}
      
    </div>
  );
};

export const StandardLayout: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children, ...rest }) => {
  return (
    <UserProvider>
      <Toaster />

      <ModalProvider>
        <div className="static flex min-h-screen flex-col" {...rest}>
          <MainNav />
          <div>{children}</div>
        </div>

        {process.env.NEXT_PUBLIC_FEEDBACK_EMAIL ? <FeedbackButton /> : null}
      </ModalProvider>
    </UserProvider>
  );
};

export const getStandardLayout: NextPageWithLayout["getLayout"] =
  function getLayout(page) {
    return <StandardLayout>{page}</StandardLayout>;
  };
